<template>
    <div class="modal" v-if="isModalOpen">
        <div class="modal-dialog">
            <div class="modal-content text-center">
                <img v-if="isSuccess" src='../../assets/images/icons/success_icon.svg' alt="Success" height="50"
                    width="50" />
                <img v-else src="../../assets/images/icons/error_icon.svg" alt="Error" height="50" width="50" />
                <h4>{{ header }}</h4>
                <p>
                    {{ paragraph }}
                </p>

                <RegularButton :buttonText="buttonTxt" class="custom-button" :color="buttonColor"
                    @click="buttonAction()" :loading="loading" />

                <a href="#" class="link" @click.prevent="linkAction()">
                    {{ routerLinkText }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import RegularButton from "./RegularButton.vue";

export default {
    components: {
        RegularButton
    },
    props: {
        isModalOpen: Boolean,
        isSuccess: Boolean,
        header: String,
        paragraph: String,
        buttonTxt: String,
        buttonColor: String,
        routerLinkText: String,
        successImageURL: String,
        errorImageURL: String,
        buttonAction: Function,
        linkAction: Function,
        loading: Boolean,
    },
    setup(props, { emit }) {
        const closeModal = () => {
            emit("closeModal");
        };

        return { closeModal };
    },
};
</script>

<style scoped>
.modal {
    /* Add styles for modal overlay and positioning */
    /* Example styles: */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.modal-dialog {
    /* Add styles for modal dialog box */
    background-color: #fff;
    border-radius: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 80%;
    margin: 100px auto;
}

.modal-content {
    border-radius: 15px;
    padding: 50px;
    display: inherit;
}

.modal-content>p {
    color: #333;
    margin-bottom: 40px;
    font-size: 14px;
}

.modal-content>.link {
    color: #9a9a9a;
    font-size: 13px;
    text-decoration: underline;
    margin-top: 20px;
}

.modal-content>p>span {
    font-weight: bold;
}

.modal-content>h4 {
    margin-bottom: 20px !important;
}

.modal-content>img {
    margin: 0 auto 30px;
}

.modal-content>.custom-button {
    display: block;
    margin: 0 auto 20px;
}
</style>
