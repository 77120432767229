<template>
    <div class="cardbox-header mb-3">
        <div class="input-group" v-if="showSearchField">
            <div class="input-group-prepend">
                <span class="input-group-text">
                    <img id="menu_icon" src="../../assets/images/icons/hamburger_icon.svg" alt="Menu Icon" />
                </span>
            </div>

            <input type="text" placeholder="Search..." class="form-control table_search" @input="emitSearchTerm"
                v-model="searchTerm" />

            <div class="input-group-append">
                <span class="input-group-text">
                    <img id="search" src="../../assets/images/icons/search_icon.svg" alt="Search Icon" />
                </span>
            </div>
        </div>

        <div class="form-group mb-0" v-if="hasFilter">
            <div class="custom-select-wrapper">
                <select id="example-select">
                    <option value="option1" selected>This Year</option>
                    <option value="option2">This Month</option>
                    <option value="option3">This Week</option>
                </select>
            </div>
        </div>
    </div>


    <div class="table-responsive">
        <table class="table table-borderless table-hover">
            <thead>
                <tr>
                    <th v-for="header in headers" :key="header.key">
                        {{ header.label }}
                    </th>
                    <th v-if="hasAnyPermission() && showActionsColumn">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="loading" class="loader-row">
                    <td :colspan="headers.length + 1">
                        <div class="linear-loader">
                            <div class="train"></div>
                        </div>
                    </td>
                </tr>

                <tr v-if="paginatedData.length === 0">
                    <td :colspan="headers.length + 1" class="no-data">
                        No Data Available
                    </td>
                </tr>

                <!-- Loop through data rows provided via props -->
                <tr v-else v-for="(row, index) in paginatedData" :key="index">

                    <!-- Loop through row items -->
                    <td v-for="(item, columnIndex) in row" :key="columnIndex">

                        <template v-if="columnIndex === 'status'">
                            <!-- Set the text color dynamically based on the status -->
                            <span :style="{ color: getStatusColor(row[columnIndex]) }">{{ row[columnIndex] }}</span>
                        </template>

                        <template v-else-if="columnIndex === 'visibility'">
                            <span :class="`badge badge-${getVisibilityColor(row[columnIndex])}`">
                                {{ row[columnIndex] }}
                            </span>
                        </template>

                        <template v-else-if="columnIndex === 'user'">
                            <div class="name-container">
                                <img :src="item.profileImage" alt="school logo"
                                    class="profile-image rounded-circle mr-10" v-if="item.profileImage"
                                    @error="handleImageError(item)" />

                                <div class="avatar-md mr-10" v-else>
                                    <span
                                        :class="`avatar-title bg-soft-${item.avatarColor} text-${item.avatarColor} font-20 rounded-circle`">
                                        {{ item.initials }}
                                    </span>
                                </div>
                                <div>
                                    <p class="m-0">{{ item.name }}</p>
                                    <span class="tableEmail" v-if="item.email">{{ item.email }}</span>
                                </div>
                            </div>
                        </template>

                        <template v-else-if="columnIndex === 'itemName'">
                            <div class="name-container">
                                <div class="avatar-md mr-10">
                                    <span
                                        :class="`avatar-title bg-soft-${item.avatarColor} text-${item.avatarColor} font-20 rounded-circle`">
                                        {{ item.initials }}
                                    </span>
                                </div>
                                <div>
                                    <p class="m-0">{{ item.name }}</p>
                                    <span class="tableEmail" v-if="item.email">{{ item.email }}</span>
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            {{ item }}
                        </template>
                    </td>

                    <!-- Actions column -->
                    <td class="actions" v-if="hasAnyPermission() && showActionsColumn">
                        <div class="actions-dropdown">
                            <button class="actions-btn" @click.stop="toggleDropdown($event)">
                                <img src="../../assets/images/icons/more_icon.svg" alt="Actions" />
                            </button>
                            <div class="actions-dropdown-content" :id="`dropdown-${index}`">
                                <a href="#" @click.prevent="viewAction(detailsData[index], id[index])"
                                    v-if="viewAction && hasPermission(viewPermission)" class="action-item">
                                    <img src="../../assets/images/icons/eye_icon.svg" alt="View" class="action-icon" />
                                    <span>View</span>
                                </a>

                                <a href="#" @click.prevent="editAction(detailsData[index], id[index])"
                                    v-if="editAction && hasPermission(editPermission)" class="action-item">
                                    <img src="../../assets/images/icons/edit_icon.svg" alt="Edit" class="action-icon" />
                                    <span>Edit</span>
                                </a>

                                <a href="#" @click.prevent="deactivateAction(detailsData[index], updatedId[index])"
                                    v-if="deactivateAction && hasPermission(deactivatePermission)" class="action-item">
                                    <img src="../../assets/images/icons/deactivate_icon.svg" alt="Deactivate"
                                        class="action-icon" />
                                    <span>{{ detailsData[index].isDeactivated ? 'Activate' : 'Deactivate' }}</span>
                                </a>

                                <a href="#" @click.prevent="deleteAction(detailsData[index], updatedId[index])"
                                    v-if="deleteAction && hasPermission(deletePermission)" class="action-item">
                                    <img src="../../assets/images/icons/trash_icon.svg" alt="Delete"
                                        class="action-icon" />
                                    <span>Delete</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="row" v-if="totalPages > 1">
            <div class="col-sm-12 col-md-3 pagination-numbers">
                <select v-model="itemsPerPage" @change="changeItemsPerPage(itemsPerPage)"
                    class=" pagination-select mr-2">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>

                <p class="m-0">Result/Page</p>
            </div>
            <!-- Pagination controls -->
            <div class="col-sm-12 col-md-9 table-pagination">
                <a href="#" @click="changePage(currentPage + 1)" class="pagination-rounded mr-16"
                    :disabled="currentPage === 1">
                    <img src="../../assets/images/icons/left_arrow_icon.svg" alt="Previous Button"
                        @click="changePage(currentPage - 1)">
                </a>

                <ul class="pagination-rounded pagination m-0">
                    <li v-for="page in displayedPages" :key="page" class="paginate_button page-item mr-16"
                        :class="{ active: currentPage === page }" @click="changePage(page)">
                        <a href="#" class="page-link">{{ page }}</a>
                    </li>
                </ul>

                <a href="#" @click="changePage(currentPage + 1)" class="pagination-rounded"
                    :disabled="currentPage === totalPages">
                    <img src="../../assets/images/icons/right_arrow_icon.svg" alt="Previous Button">
                </a>

            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch, onMounted, onUnmounted } from 'vue';
import { useUserStore } from "@/stores/user";
import { hasPermission } from "@/utils/helpers";


export default {
    props: {
        data: Array,
        headers: Array,
        id: Array,
        loading: Boolean,
        hasFilter: Boolean,
        searchStringFromParent: String,
        showActionsColumn: Boolean,
        viewAction: Function,
        editAction: Function,
        deactivateAction: Function,
        deleteAction: Function,
        searchFields: Array,
        detailsData: Array,
        totalPages: Number,
        currentPage: Number,
        changePage: Function,
        itemsPerPage: [Number, String],
        changeItemsPerPage: Function,
        handleEnterKey: Function,
        showSearchField: Boolean,
        isAPISearchNeeded: {
            type: Boolean,
            default: false
        },
        viewPermission: String,
        editPermission: String,
        deletePermission: String,
        deactivatePermission: String,
    },
    emits: ['showLoader', 'flashMessage', 'data-loaded', 'searchWord', 'update:searchStringFromParent', 'update:currentPage'],
    setup(props, { emit }) {
        const searchTerm = ref('')
        const tableData = ref([])
        const updatedId = ref([]);
        const currentPage = ref(props.currentPage);
        const itemsPerPage = ref(props.itemsPerPage);
        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)
        const activeDropdown = ref(null);

        const hasAnyPermission = () => {
            const permissions = [
                props.viewPermission,
                props.editPermission,
                props.deactivatePermission,
                props.deletePermission,
            ];

            return permissions.some(permission => permission !== undefined && permission.trim() !== '');
        };

        watch(
            () => props.data,
            (newFormattedData) => {
                tableData.value = newFormattedData
            }
        )

        watch(() => props.searchStringFromParent, (newSearchTerm) => {
            searchTerm.value = newSearchTerm
        })

        watch(() => props.currentPage, (newPage) => {
            currentPage.value = newPage
        });

        watch(() => props.id, (newVal) => {
            if (Array.isArray(newVal) && newVal.length > 0) {
                updatedId.value = newVal;
            }
        });

        const emitSearchTerm = () => {
            emit("update:searchStringFromParent", searchTerm.value)
            emit('searchWord', searchTerm.value)
            currentPage.value = 1;
        }

        const filteredData = computed(() => {
            return props.data.filter((row) => {
                // Check if any field contains the search term
                return props.searchFields.some((field) => {
                    if (field === 'itemName.name') {
                        const itemName = row.itemName;
                        if (itemName && itemName.name) {
                            const fieldValue = String(itemName.name).toLowerCase();
                            const searchTermLower = searchTerm.value.toLowerCase();
                            return fieldValue.includes(searchTermLower);
                        }
                        return false;
                    } else if (field === 'user.name') {
                        // Handle 'user.name' field separately
                        const userName = row.user && row.user.name;
                        if (userName) {
                            const fieldValue = String(userName).toLowerCase();
                            const searchTermLower = searchTerm.value.toLowerCase();
                            return fieldValue.includes(searchTermLower);
                        }
                        return false;
                    } else if (field === 'user.email') {
                        // Handle 'user.email' field separately
                        const userEmail = row.user && row.user.email;
                        if (userEmail) {
                            const fieldValue = String(userEmail).toLowerCase();
                            const searchTermLower = searchTerm.value.toLowerCase();
                            return fieldValue.includes(searchTermLower);
                        }
                        return false;
                    } else {
                        const fieldValue = String(row[field]).toLowerCase();
                        const searchTermLower = searchTerm.value.toLowerCase();
                        return fieldValue.includes(searchTermLower);
                    }
                });
            });
        })

        // Computed property for paginated data based on filtered data
        const paginatedData = computed(() => {
            const start = 0;
            const end = itemsPerPage.value;


            return props.isAPISearchNeeded ? props.data.slice(start, end) : filteredData.value.slice(start, end)
        });

        const getStatusColor = (isDeactivated) => {
            if (isDeactivated === 'Active' || isDeactivated === 'Approved') {
                return 'green'
            } else if (isDeactivated === 'Cancelled' || isDeactivated === 'Deactivated' || isDeactivated === 'Pending') {
                return 'red'
            }
        }

        const getVisibilityColor = (isPrivate) => {
            return isPrivate === 'Private' ? 'dark' : 'info'
        }

        const changePage = (page) => {
            if (page >= 1 && page <= props.totalPages) {
                // Use the changePage function from props
                props.changePage(page);

                // Emit the update:currentPage event
                emit('update:currentPage', page);
            }
        };

        const displayedPages = computed(() => {
            const range = 4; // Number of pages to display at a time
            const startPage = Math.max(1, props.currentPage - 1);
            const endPage = Math.min(props.totalPages, startPage + range - 1);

            return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
        })

        const handleImageError = (item) => {
            item.profileImage = null
        }

        const toggleDropdown = (event) => {
            const button = event.currentTarget;
            const dropdownId = button.nextElementSibling.id;
            const dropdown = document.getElementById(dropdownId);

            // Close any open dropdown
            if (activeDropdown.value && activeDropdown.value !== dropdownId) {
                document.getElementById(activeDropdown.value).style.display = 'none';
            }

            // Toggle current dropdown
            if (dropdown.style.display === 'block') {
                dropdown.style.display = 'none';
                activeDropdown.value = null;
            } else {
                // Position the dropdown
                const rect = button.getBoundingClientRect();
                dropdown.style.top = `${rect.bottom + window.scrollY}px`;
                dropdown.style.left = `${rect.left + window.scrollX}px`;
                dropdown.style.display = 'block';
                activeDropdown.value = dropdownId;
            }

            event.stopPropagation();
        };

        const closeDropdowns = () => {
            if (activeDropdown.value) {
                document.getElementById(activeDropdown.value).style.display = 'none';
                activeDropdown.value = null;
            }
        };

        onMounted(() => {
            document.addEventListener('click', closeDropdowns);
        });

        onUnmounted(() => {
            document.removeEventListener('click', closeDropdowns);
        });

        return {
            searchTerm, emitSearchTerm, paginatedData, getStatusColor, handleImageError,
            updatedId, currentPage, itemsPerPage, changePage, tableData, displayedPages, getVisibilityColor,
            hasPermission: checkPermission, hasAnyPermission, toggleDropdown, closeDropdowns
        };
    }
};
</script>

<style scoped>
.table-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pagination-numbers {
    display: flex;
    align-items: center;
}

.pagination-select {
    padding: 10px;
    border: 1px solid #F1F1F1;
    border-radius: 10px
}

.page-item.active .page-link {
    border-color: #2f5bcf;
}

.page-link {
    padding: 0.5rem 0.9rem;
}

.mr-16 {
    margin-right: 16px;
}

h4 {
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.table_search {
    border-radius: 10px;
    padding: 25px 25px 25px 0;
    background-color: #f5f6fa !important;
    border: none !important;
}

.table_search::placeholder {
    color: #333;
    font-weight: 700;
    font-size: 16px;
}

.cardbox-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-group {
    width: 40%;
    min-width: 250px;
}

.input-group-text {
    background-color: #e9ecef;
}

.input-group>.input-group-append>.input-group-text {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    background-color: #f5f6fa;
}

.input-group>.input-group-prepend>.input-group-text {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: none;
    background-color: #f5f6fa;
}

#search {
    height: 20px;
    width: 20px;
    color: #333333;
    margin-right: 5px;
}

#menu_icon {
    height: 25px;
    width: 25px;
    color: #333333;
}

.table {
    width: 100%;
    color: #333333 !important;
}

.table td {
    padding: 10px
}

.table-borderless thead,
.table-borderless td {
    border-bottom: 1px solid #f1f1f1;
}

.table-borderless thead {
    color: #9a9a9a;
    background-color: #f5f6fa;
}

.table-borderless th:first-child {
    border-top-left-radius: 10px;
}

.table-borderless th:last-child {
    border-top-right-radius: 10px;
}

.tableEmail {
    font-size: 11px;
    color: #9a9a9a;
}

td {
    vertical-align: middle !important;
}

.active {
    color: #38ca89;
}

.inactive {
    color: #f24e1e;
}

.avatar-md {
    width: 40px;
    height: 40px;
}

.rounded-circle {
    height: 40px !important;
    width: 40px !important;
    object-fit: contain;
}

.avatar-title {
    font-size: 16px !important;
}

.detailsBtn {
    padding: 0 12px;
    color: #2f5bcf !important;
    background-color: #fff !important;
    height: 32px;
    min-width: 57px;
    border: 1.5px solid #dfe1e5 !important;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 600;
    letter-spacing: 0;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    transition-duration: .28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

/* Custom select */
.custom-select-wrapper {
    position: relative;
    display: block;
    width: max-content;
}

.custom-select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 110px;
    padding: 10px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    color: #333333;
}

.custom-select-wrapper::after {
    content: url("../../assets/images/icons/outline_arrow_down_icon.svg");
    /* Replace with the path to your external SVG icon */
    width: 15px;
    /* Adjust the width of the icon as needed */
    height: 15px;
    position: absolute;
    top: 40%;
    right: 10px;
    /* Adjust the right value to control the position of the caret */
    transform: translateY(-50%);
    pointer-events: none;
}


.linear-loader {
    height: 3px;
    /* Adjust the height as needed */
    background-color: #89a6f3;
    /* Adjust the color as needed */
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    /* z-index: 100; */
    /* Ensure it's on top of other elements */
}

.train {
    width: 900px;
    /* Adjust the train's width */
    height: 7px;
    /* Should match the height of the loader */
    background-color: #2f5bcf;
    /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite;
    /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px;
        /* Start off the screen to the left */
    }

    100% {
        left: 100%;
        /* Move to the right of the screen */
    }
}

tr.loader-row td {
    padding: 0 !important;
}

.actions {
    position: relative;
}

.actions-dropdown {
    position: relative;
    display: inline-block;
}

.actions-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}

.actions-btn:hover {
    background-color: rgba(47, 91, 207, 0.1);
}

.actions-dropdown-content {
    display: none;
    position: fixed;
    min-width: 160px;
    background-color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 9999;
    padding: 8px 0;
}

.actions-dropdown:hover .actions-dropdown-content {
    display: none;
}

.action-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.2s;
}

.action-item:hover {
    background-color: #f5f6fa;
}

.action-icon {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.no-data {
    text-align: center;
    font-weight: 900;
}

.name-container {
    display: flex;
    align-items: center;
}


.badge {
    font-size: 14px;
    font-family: "Inter SemiBold", sans-serif;
}
</style>