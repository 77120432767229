<template>
    <button
        :type="buttonType"
        :class="[colorClass, customClass]"
        @click="onClick"
        :disabled="loading"
        v-if="!loading"
    >
        <i :class="iconClass" v-if="iconText"></i>
        <span class="text">{{ buttonText }}</span>
    </button>

    <button :class="[colorClass, customClass]" type="button" disabled v-else>
        <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
        ></span>
        <span class="sr-only">Loading...</span>
    </button>
</template>

<script>
export default {
    props: {
        buttonText: {
            type: String,
            default: "Click Me", // Default text if not specified
        },
        iconText: String,
        color: {
            type: String,
            default: "black",
        },
        class: String,
        buttonType: {
            type: String,
            default: "button",
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, { emit }) {
        const onClick = () => {
            // Emit a custom event when the button is clicked
            emit("clicked");
        };

        return { onClick };
    },
    computed: {
        iconClass() {
            // Combine the default icon class with the provided icon text
            return `mdi ${this.iconText} mr-1`;
        },
        customClass() {
            return this.class ? this.class : ""; // Use the custom class if provided
        },
        colorClass() {
            return this.color ? this.color : "blue";
        },
    },
};
</script>

<style scoped>
.custom-button {
    border-radius: 4px;
    background-color: var(--black);
    color: white;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 10px 24px;
    border: none;
}

@media (min-width: 1147px) {
    .custom-button {
        font-size: 14px !important;
    }
}

.blue {
    background-color: #2f5bcf;
    color: white;
}

.gray {
    background-color: #434343;
    color: white;
}

.white {
    background-color: #fff;
    color: black;
    padding: 10px 24px;
    font-size: 16px;
}

.error {
    background-color: #F75549;
    color: white;
}

.black {
    background-color: var(--black);
}

.disabled {
    background-color: #b1b1b1;
    color: white;
}



</style>