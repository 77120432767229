<template>
    <button
        :type="buttonType"
        :class="[colorClass, customClass]"
        @click="onClick"
        :disabled="loading"
        v-if="!loading"
    >
        <i :class="iconClass" v-if="iconText"></i>
        <span class="text">{{ buttonText }}</span>
    </button>

    <button :class="[colorClass, customClass]" type="button" disabled v-else>
        <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
        ></span>
        <span class="sr-only">Loading...</span>
    </button>
</template>

<script>
export default {
    props: {
        buttonText: {
            type: String,
            default: "Click Me", // Default text if not specified
        },
        iconText: String,
        color: {
            type: String,
            default: "blue",
        },
        class: String,
        loading: {
            type: Boolean,
            default: false,
        },
        buttonType: {
            type: String,
            default: "button",
        },
    },
    setup(props, {emit}) {
        const onClick = () => {
            // Emit a custom event when the button is clicked
            emit("clicked");
        };

        return { onClick }
    },
    computed: {
        iconClass() {
            // Combine the default icon class with the provided icon text
            return `mdi ${this.iconText} mr-1`;
        },
        customClass() {
            return this.class ? `custom-button ${this.class}` : "custom-button"; // Use the custom class if provided
        },
        colorClass() {
            return this.color ? this.color : "blue";
        },
    },
};
</script>

<style scoped>
.custom-button {
    border-radius: 8px;
    background-color: var(--black);
    color: white;
    font-size: 16px;
    letter-spacing: 0.1px;
    font-weight: 600;
    padding: 10px 24px;
    border: none;
}
.blue {
    background-color: var(--black);
    color: white;
}

.gray {
    background-color: var(--gray);
    color: white;
}
</style>